import { ISettingsParam } from '@wix/tpa-settings';

export type IFeedSettingsParams = {
  showCreatePost: ISettingsParam<boolean>;
  showFeedTitle: ISettingsParam<boolean>;
  feedTitle: ISettingsParam<string | null>;
};

export const feedSettingsParams: IFeedSettingsParams = {
  feedTitle: {
    key: 'feedTitle',
    getDefaultValue: ({ t }) =>
      t('groups-web.settings.TextTab.feedTitle.default'),
  },
  showCreatePost: {
    getDefaultValue: () => true,
    key: 'showCreatePost',
  },
  showFeedTitle: {
    getDefaultValue: () => true,
    key: 'showFeedTitle',
  },
};
